import { AxiosResponse } from 'axios';
import axiosInstance from '../';
import { AuthResponse } from '../models/models';
import { responseHandler } from '../errors/responseHandler';

export default class AuthService {
	static async signIn(email: string, password: string): Promise<AxiosResponse<AuthResponse>> {
		return responseHandler(() => {
			return axiosInstance.post('/auth/sign-in', { email, password });
		});
	}

	static async signUp(
		email: string,
		firstName: string,
		password: string,
		occupation?: string,
	): Promise<AxiosResponse<AuthResponse>> {
		return responseHandler(() => {
			return axiosInstance.post('/auth/sign-up', {
				email,
				firstName,
				occupation,
				password,
			});
		});
	}

	static async verify(): Promise<AxiosResponse<Pick<AuthResponse, 'id' | 'email'> | null>> {
		return axiosInstance.get('/auth/me');
	}

	static async signOut(): Promise<void> {
		return axiosInstance.post('/auth/sign-out');
	}

	static async confirmEmail(token: string): Promise<void> {
		return axiosInstance.post('/auth/confirm-email', { token: token });
	}

	static async changePassword(oldPassword: string, newPassword: string): Promise<void> {
		return axiosInstance.post('/auth/change-password', { oldPassword, newPassword });
	}

	static async resetPassword(email: string): Promise<void> {
		return axiosInstance.post('/auth/reset-password', { email });
	}

	static async confirmResetPassword(token: string, newPassword: string): Promise<void> {
		return axiosInstance.post('/auth/confirm-reset-password', { token, newPassword });
	}

	static async requestDeleteAccount(id: number, email: string): Promise<void> {
		return axiosInstance.post('/auth/request-delete-account', { id, email });
	}

	static async confirmDeleteAccount(token: string): Promise<void> {
		return axiosInstance.post('/auth/confirm-delete-account', { token: token });
	}

	static async rejectDeleteAccount(token: string): Promise<void> {
		return axiosInstance.get('/auth/reject-delete-account', { params: { token: token } });
	}
}
