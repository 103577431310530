import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { BackIcon } from "../../../../components/icon/svg-icons/Icon";
import style from "./backButton.module.scss";
interface IBackButton {
    isCompact: boolean;
}
const BackButton: FC<IBackButton> = ({ isCompact = false }) => {
    const navigate = useNavigate();

    return (
        <button
            className={`${isCompact ? style.backButtonCompact : style.backButton}`}
            onClick={() => navigate(-1)}
        >
            <BackIcon className={style.icon} />
            {!isCompact && <span className={style.backText}>Назад</span>}
        </button>
    );
};

export default BackButton;