import React, { lazy } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { commonPages, dashboardMenu, userPages } from '../menu';
import { isFrameMode } from '../helpers/helpers';

const LandingPage = lazy(() => import('../pages/WidgetsPage'));
const PricingPage = lazy(() => import('../pages/dashboard/PlansPage'));
const SupportPage = lazy(() => import('../pages/dashboard/SupportPage'));
const ForumPage = lazy(() => import('../pages/dashboard/ForumPage'));
const ProfilePage = lazy(() => import('../pages/profile'));
const LoginPage = lazy(() => import('../pages/auth/Login'));
const Page404 = lazy(() => import('../pages/common/Page404'));
const ConfirmEmail = lazy(() => import('../pages/auth/ConfirmEmail'));
const ConfirmDeleteAccount = lazy(() => import('../pages/auth/ConfirmDeleteAccount'));
const ChangePassword = lazy(() => import('../pages/auth/ChangePassword'));
const BuilderPage = lazy(() => import('../pages/builder/BuilderPage'));
const AnalyticsPage = lazy(() => import('../pages/analytics/Common'));
const WidgetDataPage = lazy(() => import('../pages/analytics/Widget'));
const PAGE_404 = lazy(() => import('../pages/common/Page404'));

export const ContentRoutes = () => {
	const builderPagePath = isFrameMode() ? '/' : commonPages.builder.path;

	return (
		<Routes>
			{!isFrameMode() && (
				<>
					<Route path='/auth-pages' element={<Outlet />}>
						<Route path='login' element={<LoginPage key='logins' />} />
						<Route path='sign-up' element={<LoginPage key='signUp' isSignUp />} />
						<Route path='confirm-email' element={<ConfirmEmail key='ConfirmEmail' isConfirmEmail />} />
						<Route path='confirm-delete-account' element={<ConfirmDeleteAccount key='ConfirmDeleteAccount' isConfirmDeleteAccount />} />
						<Route path='change-password' element={<ChangePassword key='ChangePassword' isConfirmChangePassword />} />
					</Route>

					<Route path={dashboardMenu.widgets.path} element={<LandingPage />} />
					<Route path={dashboardMenu.pricing.path} element={<PricingPage />} />
					<Route path={dashboardMenu.support.path} element={<SupportPage />} />
					<Route path={dashboardMenu.forum.path} element={<ForumPage />} />
					<Route path={userPages.profile.path} element={<ProfilePage />} />
					<Route path={userPages.analytics.path} element={<AnalyticsPage />} />
					<Route path={userPages.analytics_instance.path} element={<WidgetDataPage />} />
					<Route path={userPages.analytics_instance.path} element={<WidgetDataPage />} />
				</>
			)}

			<Route path={builderPagePath} element={<BuilderPage />} />
			<Route path={commonPages.page404.path} element={<Page404 />} />
			<Route path='*' element={<PAGE_404 />} />
		</Routes>
	);
};
