import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ThemeProvider } from 'react-jss';
import { ReactNotifications } from 'react-notifications-component';
import { Route, Routes, useLocation } from 'react-router-dom';

import Aside from '../layout/Aside/Aside';
import Wrapper from '../layout/Wrapper/Wrapper';
import Portal from '../layout/Portal/Portal';
import { commonPages, promotionPages } from '../menu';
import useDarkMode from '../hooks/useDarkMode';
import COLORS from '../common/data/enumColors';
import { getOS, isFrameMode } from '../helpers/helpers';
import PromotionInvitePage from '../pages/promotion/invite/PromotionInvitePage';
import { sendMetrik } from '../utils/metrika';
import browserHistory from '../routes/history';

const App = () => {
	const location = useLocation();
	getOS();
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};

	useEffect(() => {
		sendMetrik('hit', window.location.href);
	}, [location]);

	// Используем useRef для хранения предыдущего пути
	const prevPathRef = useRef<string | null>(null);
	// Получаем предыдущий путь из localStorage при монтировании компонента
	useEffect(() => {
		const storedPrevPath = localStorage.getItem('prevPath');
		if (storedPrevPath) {
			prevPathRef.current = storedPrevPath;
			console.log('Загружен предыдущий путь из localStorage:', storedPrevPath);
		}
	}, []);

	useEffect(() => {
		const unlisten = browserHistory.listen((update) => {
			const currentPath = update.location.pathname;

			// Логируем текущий путь и предыдущий путь
			console.log('Текущий путь:', currentPath);
			console.log('Предыдущий путь:', prevPathRef.current);

			// Сохраняем текущий путь в localStorage как предыдущий перед переходом на новый
			if (prevPathRef.current !== null) {
				localStorage.setItem('prevPath', prevPathRef.current);
			}

			// Обновляем ref с текущим путем
			prevPathRef.current = currentPath;
		});

		return () => {
			unlisten(); // Очистка слушателя при размонтировании компонента
		};
	}, []);

	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'light');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
		};
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	// @ts-ignore
	// const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	// const ref = useRef(null);
	// useFullscreen(ref, fullScreenStatus, {
	// 	onClose: () => setFullScreenStatus(false),
	// });

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		document.body.classList.add('modern-design');

		// if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
		// 	document.body.classList.add('modern-design');
		// } else {
		// 	document.body.classList.remove('modern-design');
		// }
	});

	//	Add paths to the array that you don't want to be "Aside".
	const withOutAsidePages = [
		commonPages.login.path,
		commonPages.signUp.path,
		commonPages.confirmEmail.path,
		commonPages.changePassword.path,
		commonPages.builder.path,
	];

	return (
		<ThemeProvider theme={theme}>
			<div className='app'>
				{!isFrameMode() && (
					<Routes>
						{withOutAsidePages.map((path) => (
							<Route key={path} path={path} element={null} />
						))}

						<Route
							path={promotionPages.invite.path}
							element={<PromotionInvitePage />}
						/>
						<Route path='*' element={<Aside />} />
					</Routes>
				)}

				<Wrapper />

				<div className='mobile-block'>
					Для входа в личный кабинет перейдите в полноэкранную версию сайта
				</div>
			</div>

			<Portal id='portal-notification'>
				<ReactNotifications />
			</Portal>
		</ThemeProvider>
	);
};

export default App;
