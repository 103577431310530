import { pickBy } from 'lodash';

export const dashboardMenu = {
	widgets: {
		id: 'widgets',
		text: 'Виджеты',
		path: '/',
		icon: 'WidgetIcon',
		subMenu: null,
	},
	pricing: {
		id: 'pricing',
		text: 'Тарифы',
		path: '/pricing',
		icon: 'PlansIcon',
		subMenu: null,
	},
	support: {
		id: 'support',
		text: 'Тех поддержка',
		path: '/support',
		icon: 'SupportIcon',
		subMenu: null,
	},
	forum: {
		disabled: true,
		id: 'forum',
		text: 'Форум',
		path: '/forum',
		icon: 'ForumIcon',
		subMenu: null,
	},
};

export const activeDashboardMenu = pickBy(dashboardMenu, (value) => {
	return !('disabled' in value && value.disabled);
});

export const promotionPages = {
	invite: {
		id: 'Invite',
		text: 'Invite',
		path: '/promotion/invite',
	},
};

export const userPages = {
	profile: {
		id: 'profile',
		text: 'Профиль',
		path: '/profile',
		icon: 'Dashboard',
		subMenu: null,
	},
	analytics: {
		id: 'analytics',
		text: 'Analytics',
		path: 'analytics',
	},
	analytics_instance: {
		id: 'analytics_instance',
		text: 'Widget',
		path: 'analytics/instance/:id',
	},
};

export const commonPages = {
	auth: {
		id: 'auth',
		text: 'Auth Pages',
		icon: 'Extension',
	},
	login: {
		id: 'login',
		text: 'Login',
		path: 'auth-pages/login',
		icon: 'Login',
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: 'auth-pages/sign-up',
		icon: 'PersonAdd',
	},
	confirmEmail: {
		id: 'confirmEmail',
		text: 'Confirm Email',
		path: 'auth-pages/confirm-email',
		icon: 'Extension',
	},
	confirmDeleteAccount: {
		id: 'confirmDeleteAccount',
		text: 'Confirm Delete Account',
		path: 'auth-pages/confirm-delete-account',
		icon: 'Extension',
	},
	changePassword: {
		id: 'changePassword',
		text: 'Confirm Change Password',
		path: 'auth-pages/change-password',
		icon: 'Extension',
	},
	builder: {
		id: 'builder',
		text: 'Builder',
		path: 'builder',
		icon: 'Extension',
	},
	page404: {
		id: 'Page404',
		text: '404 Page',
		path: 'auth-pages/404',
		icon: 'ReportGmailerrorred',
	},
};
